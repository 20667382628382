import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  HistoryEntriesQuery,
  ContentfulHistoryEntry,
  Maybe,
} from "types/graphql-types";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";

type HistoryEntry = Maybe<
  Pick<ContentfulHistoryEntry, "title" | "text" | "year" | "id">
>;

const History = () => {
  const { contentfulAboutUsPage }: HistoryEntriesQuery = useStaticQuery(graphql`
    query HistoryEntries {
      contentfulAboutUsPage {
        historySupertitle
        historyTitle
        historyEntries {
          text
          title
          year
          id
        }
      }
    }
  `);

  if (!contentfulAboutUsPage) {
    throw new Error(
      "Not all Contentful requirements met: contentfulAboutUsPage"
    );
  }

  const {
    historySupertitle,
    historyTitle,
    historyEntries,
  } = contentfulAboutUsPage;

  const entries = historyEntries!.sort(
    (a: HistoryEntry, b: HistoryEntry) => a!.year! - b!.year!
  );

  return (
    <ContentWidthWrapper>
      <Wrapper id="history">
        <TextWrapper>
          <SmallTitle variant="subtitle1">{historySupertitle}</SmallTitle>
          <Typography variant="h2">{historyTitle}</Typography>
        </TextWrapper>
        <Divider />
        <StartDate>
          <DateLabel>{entries[0]!.year}</DateLabel>
          <DateTitle variant="subtitle1">{entries[0]!.title}</DateTitle>
          <Typography component="div" variant="body2">
            {entries[0]!.text}
          </Typography>
        </StartDate>

        <VerticalTimeline>
          {entries.slice(1).map((entry: HistoryEntry) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={entry!.year?.toString()}
              contentArrowStyle={{ display: "none" }}
              contentStyle={{ backgroundColor: "transparent" }}
              iconStyle={{ display: "none" }}
              key={entry!.id!}
            >
              <DateTitle variant="subtitle1">{entry!.title}</DateTitle>
              <Typography variant="body2" component="div">
                {entry!.text}
              </Typography>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Wrapper>
    </ContentWidthWrapper>
  );
};

export default History;

const Wrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.stone.main};
    margin: 0 auto ${theme.spacing(9)}px;
    max-width: 976px;
    padding: ${theme.spacing(4, 3, 0)};

    ${theme.breakpoints.up("md")} {
        padding: ${theme.spacing(9, 13, 0)};
    }
    `
);

const TextWrapper = styled.div(
  ({ theme }) => `
    margin: 0 auto;
    max-width: 440px;
    text-align: center;
`
);

const Divider = styled.hr(
  ({ theme }) => `
    background-color: ${theme.palette.divider};
    margin: ${theme.spacing(3)}px auto ${theme.spacing(7)}px;
    width: 100px;
`
);

const SmallTitle = styled(Typography)(
  ({ theme }) => `
    margin: ${theme.spacing(0, 0, 2)};
`
);

const StartDate = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
    text-align: center;
    margin: 0 auto ${theme.spacing(5)}px;
`
);

const DateTitle = styled(Typography)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)}px;
`
);

const DateLabel = styled.div(
  ({ theme }) => `
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto ${theme.spacing(1.5)}px;
  padding: ${theme.spacing(0.5, 2)};
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: 75px;
  }
`
);
