import React, { FC } from "react";
import styled from "styled-components";
import ContentWidthWrapper from "./ContentWidthWrapper";
import { Grid, Typography } from "@material-ui/core";
import LinkButton from "./LinkButton";
import ContentfulImage from "./ContentfulImage";

interface Props {
  headline: string;
  subHeadline: string;
  label: string;
  image: string;
}

const StoreFinder: FC<Props> = ({ headline, subHeadline, label, image }) => {
  return (
    <Container id="stores">
      <ContentWidthWrapper width={976}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={5} md={6}>
            <NarrowContent>
              <Typography variant="h2" gutterBottom>
                {headline}
              </Typography>
              <Typography variant="subtitle1">{subHeadline}</Typography>
              <LinkButton
                contained
                href="https://www.depot-online.com/filialen"
              >
                {label}
              </LinkButton>
            </NarrowContent>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <ContentfulImage url={image} width={"100%"} />
          </Grid>
        </Grid>
      </ContentWidthWrapper>
    </Container>
  );
};

export default StoreFinder;

const Container = styled("div")(
  ({ theme }) => `
    background-color: ${theme.palette.white.main};
    padding: ${theme.spacing(0, 3)};
    margin-bottom: ${theme.spacing(5)}px;
    ${theme.breakpoints.up("md")} {
      padding: ${theme.spacing(10, 7)};
      margin-bottom: ${theme.spacing(5)}px;

    }
    `
);

const NarrowContent = styled("div")(
  ({ theme }) => `
  margin: 0 auto ${theme.spacing(5)}px;
  ${theme.breakpoints.up("sm")} {
    padding-right: ${theme.spacing(3)}px;
    margin-bottom: 0;
  }
  a {
    margin-top: ${theme.spacing(3)}px;
  }
`
);
