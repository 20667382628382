import React from "react";
import styled from "styled-components";
import { Typography, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import LinkButton from "../LinkButton";
import { useStaticQuery, graphql } from "gatsby";
import { FactsQuery } from "types/graphql-types";
import Icons from "./Icons";
import theme from "src/constants/theme";

const Facts = ({ contentfulAboutUsPage, facts }) => {

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Wrapper id="facts">
      <Content>
        <Top container justify="center">
          <Grid item xs={12} sm={5} md={6}>
            <Typography variant="h1" component="h2">
              {contentfulAboutUsPage?.factsTitle}
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid
              item
              xs={12}
              sm={7}
              md={6}
              container
              justify="flex-end"
              alignItems="flex-end"
            >
              <LinkButton
                contained={true}
                href={contentfulAboutUsPage?.factsOtherYears?.file?.url!}
              >
                {contentfulAboutUsPage?.factsOtherYearsLabel}
              </LinkButton>
            </Grid>
          </Hidden>
        </Top>

        <Bottom container wrap={desktop ? "nowrap" : "wrap"}>
          <Box item xs={12} md={4}>
            <div>
              <Icons type="group" />
            </div>
            <div>
              <BigFont>{facts[0]?.value!}</BigFont>
              <Typography variant="h3" gutterBottom>
                {facts[0]?.title!}
              </Typography>
              <Typography variant="caption">{facts[0]?.subtitle!}</Typography>
            </div>
          </Box>

          <Box item xs={12} md={4}>
            <BiggestFont>{facts[1]?.value!}</BiggestFont>

            <div>
              <Typography variant="h3" gutterBottom>
                {facts[1]?.title!}
              </Typography>

              <Table>
                <TableLeft>
                  <Typography variant="body2">{facts[2]?.title!}</Typography>
                  <Typography variant="body2">{facts[3]?.title!}</Typography>
                  <Typography variant="body2">{facts[4]?.title!}</Typography>
                  <Typography variant="body2">{facts[5]?.title!}</Typography>
                </TableLeft>
                <TableRight>
                  <Typography variant="body2">{facts[2]?.value!}</Typography>
                  <Typography variant="body2">{facts[3]?.value!}</Typography>
                  <Typography variant="body2">{facts[4]?.value!}</Typography>
                  <Typography variant="body2">{facts[5]?.value!}</Typography>
                </TableRight>
                <Icons type="store" />
              </Table>
            </div>
          </Box>
          <Box item xs={12} md={4}>
            <div>
              <Icons type="growth" />
            </div>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                style={{ display: "inline-block", marginRight: 16 }}
              >
                {facts[6]?.valuePrefix!}
              </Typography>
              <BigFont style={{ display: "inline-block" }}>
                {facts[6]?.value!}
              </BigFont>
              <Typography variant="h3" gutterBottom>
                {facts[6]?.title!}
              </Typography>

              <Typography variant="caption">{facts[6]?.subtitle!}</Typography>
            </div>
          </Box>
        </Bottom>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            sm={7}
            md={6}
            container
            justify="flex-end"
            alignItems="flex-end"
          >
            <LinkButton
              contained
              href={contentfulAboutUsPage?.factsOtherYears?.file?.url!}
            >
              {contentfulAboutUsPage?.factsOtherYearsLabel}
            </LinkButton>
          </Grid>
        </Hidden>
      </Content>
    </Wrapper>
  );
};

export default Facts;

const Content = styled.div`
  max-width: 976px;
  margin: 0 auto;
`;

const Wrapper = styled.div(
  ({ theme }) => `
  width; 100%;
  background-color: ${theme.palette.lightmoss.main};
  padding: ${theme.spacing(5, 3)};
  margin-bottom: ${theme.spacing(6)}px;

  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(11, 7, 10)}
  }
  ${theme.breakpoints.up("lg")} {
    padding: ${theme.spacing(11, 19, 10)}
  }
`
);
const Top = styled(Grid)(
  ({ theme }) => `

  padding: ${theme.spacing(0, 0, 3)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(0, 7, 3)}px;
  }
`
);

const Bottom = styled(Grid)(
  ({ theme }) => `
   display: flex;
    justify-content: center,
    align-items: center;
  ${theme.breakpoints.up("md")} {
    display: flex;
    justify-content: space-between;
   }
`
);

const Box = styled(Grid)(
  ({ theme }) => `
  border: 1px solid #707070;
  padding: ${theme.spacing(4, 3)};
  margin-bottom: ${theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

${theme.breakpoints.up("sm")} {
  flex-direction: row;
  justify-content: space-between;
  }
${theme.breakpoints.up("md")} {
  padding-bottom: ${theme.spacing(5)}px;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: ${theme.spacing(3)}px;
  margin-bottom: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
`
);

const Table = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const TableLeft = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${theme.spacing(2)}px;
  & > p {
    line-height: 2;
  }

`
);
const TableRight = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${theme.spacing(3)}px;
  & > p {
    font-weight: 700;
    line-height: 2;
  }
`
);
const BigFont = styled.p(
  ({ theme }) => `
font-size: 40px;
  font-weight: 800;
  margin: 0 0 16px 0;
}
${theme.breakpoints.up("sm")}{
  font-size: 56px;
}
`
);
const BiggestFont = styled.p(
  ({ theme }) => `
  font-size: 56px;
  font-weight: 800;
  margin: 0 0 16px 0;
}
${theme.breakpoints.up("sm")}{
  font-size: 76px;
}
`
);
